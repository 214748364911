#img-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.754);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #fa-circle-left {
    color: lightgray;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
  #fa-circle-right {
    color: lightgray;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
  #exit {
    padding: 0.5em 1rem;
    font-weight: bold;
    border: none;
    background-color: white;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 2rem;
  }

  #exit-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
  }
  #img-texts {
    max-width: 70%;
    object-fit: cover;
  }
  img {
    width: 100%;
  }
}
