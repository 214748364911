// full size screens
nav {
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  .nav-body {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 8%;
    padding: 0.6rem 0;
    img {
      filter: brightness(0);
      width: 8rem;
    }
    .nav-links {
      display: flex;
      ul {
        display: flex;
        li {
          list-style: none;
          a {
            font-size: 14px;
            margin-right: 4rem;
            color: black;
            text-decoration: none;
            transition: all 0.2s;
          }
        }
        li:hover {
          font-weight: 600;
          cursor: pointer;
        }
      }

      .active-style {
        font-weight: "bold";
        padding: "0.4rem 1rem";
        background: "lightgray";
      }

      button {
        padding: 1rem 2rem;
        border: 2px black solid;
        background-color: white;
        color: black;
        font-size: 15px;
        transition: all 0.3s ease;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
      button:hover {
        background-color: black;
        color: white;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.2s ease;
        border-radius: 0.4rem;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
      align-items: center;
    }
  }

  .fa-rectangle-xmark {
    display: none;
  }
  .fa-bars {
    display: none;
  }
}

// mobile screens

@media screen and (max-width: 850px) {
  nav {
    .nav-body {
      padding: 0.5rem;
      margin: 0 4%;

      img {
        filter: brightness(0);
        width: 8rem;
      }
      .nav-links {
        width: 100%;
        height: 100vh;
        display: none;
        flex-direction: column;
        background-color: white;
        align-self: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        ul {
          display: flex;
          flex-direction: column;
          height: 70%;
          justify-content: space-around;
          align-items: center;
          text-align: center;
          li {
            a {
              text-align: center;
              margin: 0;
              font-size: 24px;
            }
          }
        }

        button {
          font-size: 28px;
          font-weight: 500;
          border: black 4px solid;
        }
      }
    }
  }

  .fa-rectangle-xmark {
    margin-top: 2rem;
    width: 100%;
    margin-left: 80%;
    display: flex !important;
  }

  .display {
    display: flex !important;
  }
  .None {
    display: none !important;
  }

  .active-style {
    background-color: black;
  }
}
