.home-container {
  background-color: #e7e7e7;
  height: 100vh;
  .background-img {
    display: flex;
    .mid-main-text {
      color: white;
      position: absolute;
      text-align: center;
      top: 30%;
      left: 10%;
      right: 10%;

      h1 {
        letter-spacing: 4px;
        font-size: 96px;
        font-weight: 600;
      }

      p {
        letter-spacing: 4px;
        font-size: 24px;
        margin-bottom: 0.5rem;
        font-weight: 300;
      }

      button {
        margin-top: 2rem;
        background-color: transparent;
        color: white;
        border: white solid 2px;
        padding: 1rem 2rem;
        font-size: 18px;
        transition: all 0.3s ease;

        a {
          color: inherit;
        }
      }
      button:hover {
        background-color: white;
        color: black;
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }

    background-color: black;
    height: 99vh;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      opacity: 0.5;
      filter: blur(1px);
    }
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 850px) {
  .home-container {
    width: 100%;
    height: 95vh;
    .background-img {
      height: 100%;
      .mid-main-text {
        left: 5%;
        right: 5%;

        h1 {
          font-size: 34px;
        }
        p {
          font-size: 12px;
          font-weight: 300;
        }

        button {
        }
      }

      img {
      }
    }
  }
}
