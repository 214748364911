.contacts-container {
  // min-height: 100vh;
  .contact-header {
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    flex-direction: column;
    align-items: center;
    .title {
      display: flex;
      align-items: baseline;
      margin-bottom: 1rem;
      margin-top: 3rem;
    }

    p {
      margin-right: 1rem;
      font-size: 16px;
      letter-spacing: 2px;
      text-align: center;
    }
    h2 {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 40vh);
    color: #3a3a3a;
    width: 100%;
    margin-bottom: 4rem;

    label {
      margin-bottom: 0.5rem;
    }

    input {
      margin-bottom: 1rem;
      width: 100%;
      padding: 0.3rem;
      font-size: 18px;
      max-width: 50%;
    }

    textarea {
      width: 100%;
      padding: 0.3rem;
      font-size: 18px;
      max-width: 50%;
    }

    button {
      margin-top: 1rem;
      padding: 1rem 2rem;
      background: none;
      border: black 1px solid;
      transition: all 0.4s ease;
    }
    button:hover {
      color: white;
      background-color: black;
      cursor: pointer;
      border-radius: 0.8rem;
    }
  }
}

// mobile view
@media screen and (max-width: 850px) {
  .contacts-container {
    .contact-header {
      padding-top: 4rem;
      padding-bottom: 4rem;
      margin-bottom: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        display: flex;
        align-items: baseline;
        margin-bottom: 1rem;
      }

      .header-subtext {
        margin-left: 10%;
        margin-right: 10%;
      }
      p {
        font-size: 12px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
      h2 {
        font-size: 20px;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100vh - 40vh);
      color: #3a3a3a;
      width: 100%;

      label {
        margin-bottom: 0.5rem;
      }

      input {
        margin-bottom: 1rem;
        width: 100%;
        padding: 0.3rem;
        font-size: 18px;
        max-width: 70%;
      }

      textarea {
        width: 100%;
        padding: 0.3rem;
        font-size: 18px;
        max-width: 70%;
      }

      button {
        margin-top: 1rem;
        padding: 1rem 2rem;
        background: none;
        border: black 1px solid;
      }
      button:hover {
        color: white;
        background-color: black;
      }
    }
  }
}
