.gallery-container {
  // min-height: 100vh;
  .gallery-header {
    background-color: #e7e7e7;
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding-top: 8rem;
    padding-bottom: 4rem;
    margin-bottom: 4rem;

    p {
      margin-right: 1rem;
      font-size: 20px;
      letter-spacing: 2px;
      text-align: center;
    }
    h2 {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;
    }
  }

  .img-selection {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    h3 {
      font-weight: 400;
      text-align: center;
      margin: 0 2rem;
    }

    h3:hover {
      cursor: pointer;
      font-weight: 500;
    }
  }

  .gallery-images {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .gallery-img-container {
      width: 10rem;
      height: 10rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        padding: 0.2rem;
        object-fit: cover;
        transition: all ease 0.5s;
      }

      img:hover {
        cursor: pointer;
        object-position: 2px;
        transition: all ease 0.3s;
      }
    }
  }
}

// mobile view
@media screen and (max-width: 850px) {
  .gallery-container {
    // position: relative;
    // min-height: 100vh;
    .gallery-header {
      display: flex;
      align-items: baseline;
      justify-content: center;
      padding-top: 4rem;
      padding-bottom: 4rem;
      margin-bottom: 4rem;
      p {
        font-size: 12px;
        margin-right: 0.5rem;
      }
      h2 {
        font-size: 20px;
      }
    }

    .img-selection {
      display: flex;
      color: rgb(103, 103, 103);
      h3 {
        font-weight: 400;
        font-size: 14px;
      }
      h3:hover {
        font-weight: 500;
        color: black;
      }
    }
    .gallery-images {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .gallery-img-container {
        width: 10rem;
        height: 10rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          padding: 0.2rem;
          object-fit: cover;
        }
      }
    }
  }
}
